.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    background: #000000;
    position: relative;
    padding: 0;
    margin-top: 90px;
    box-sizing: border-box;
    overflow: hidden;
}

.circle {
    position: absolute;
    bottom: 50px;
    left: 0%;
    width: 100%;
    height: 1000px;
    z-index: 1;
    opacity: 0.25;
    background-size: cover;
    filter: blur(40px);
    border-radius: 50%;
}

.dotArray {
    position: absolute;
    top: 32%;
    left: 3%;
    opacity: 0.08;
    transform: translateY(-50%);
    width: 175px;
    height: 175px;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.dotArray2 {
    position: absolute;
    top: 78%;
    left: 87%;
    opacity: 0.08;
    transform: translateY(-50%);
    width: 175px;
    height: 175px;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.headerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    z-index: 3;
}

.title {
    width: fit-content;
    max-width: 100%;
    line-height: 75px;
    font-weight: 700;
}

.title h1 {
    font-size: 74px;
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-weight: 600;
}

.secondaryText {
    text-align: center;
    font-size: 74px;
    margin-bottom: 20px;
    font-weight: 700;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
