.dropdown {
    position: relative;
    width: 100%;
    
    cursor: pointer;
}

.dropdownHeader {
    padding: 0px; /* Adjust the padding to match the textboxes */
    padding-left: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 34px; /* Match the height of the textboxes */
    font-size: 14px; /* Adjust font size if needed */
}

.dropdownHeader:hover {
    border-color: #c06ec3;
}

.dropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    font-size: 14px; /* Adjust font size if needed */

    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
    padding: 5px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
    font-size: 0.8em;
}

/* Rotate the chevron when dropdown is open */
.rotate {
    transform: rotate(180deg);
}



