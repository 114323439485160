.clientNavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Drop shadow */
    transition: padding 0.3s ease, height 0.3s ease, box-shadow 0.3s ease;
}

.clientNavBar.scrolled {
    padding: 5px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on scroll */
}

.navLeft {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.ferociousLogo {
    transition: max-height 0.3s ease, width 0.3s ease, transform 0.3s ease;
    width: 120px;
}

.ferociousLogo:hover {
    transform: scale(.9);
}

.clientNavBar.scrolled .ferociousLogo {
    max-height: 40px; /* Adjusted to max-height */
    width: auto;
}

.navMiddle {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navLink {
    margin-left: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    transition: background-color 0.3s;
}

.navLink span {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 20px; /* Adjust size as needed */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    margin-right: 8px; /* Adjust spacing as needed */
}

.navLink:hover {
    color: #6A479C;
    text-decoration: none;
}

.navRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.userContainer {
    position: relative;
}

.userInfo {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    margin-right: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease;
    max-height: 100px; /* Adjust as necessary */
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease, width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.clientNavBar.scrolled .userInfo {
    opacity: 0.7;
    max-height: 30px; /* Adjust as necessary */
    padding: 5px 20px; /* Adjust as necessary */
}

.userEmail {
    font-weight: 400;
    font-size: 16px;
    color: #5F6368;
    display: block;
    margin-bottom: 8px; /* Space between email and client name */
    line-height: 60%;
    transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease, width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.clientName {
    display: block;
    font-size: 20px;
    color: #37393C;
    font-weight: 600;
    margin-bottom: 0px;
    transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease, width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.profileIcon {
    width: 45px;
    height: 45px;
    background-color: #E5E5E5; /* Adjust the background color as needed */
    border-radius: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    margin-right: 12px;
    transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease, width 0.3s ease, height 0.3s ease, font-size 0.3s ease;
}

.clientNavBar.scrolled .userEmail {
    display: none;
}

.clientNavBar.scrolled .profileIcon {
    width: 40px;
    height: 40px;
    font-size: 16px;
}

.dropdownMenu {
    position: absolute;
    top: 100%; /* Positions the dropdown directly below the userInfo */
    right: 80px; /* Aligns the right edge of the dropdown with the right edge of the userInfo */
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    z-index: 1001;
}

.logoutButton {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s;
}

.navLink Link{
    
}

.logoutButton:hover {
    background-color: #f0f0f0;
    border-radius: 10px;
}
