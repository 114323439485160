.tabBar {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-top: 130px;
    padding-left: 20px;
    margin-left: 40px;
    margin-right: 40px;
    z-index: 500;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    color: #A0A0A0;
    transition: color ease 0.3s;
}

.tab:hover{
    color: #2E2E2E;
}

.tab.active {
    border-bottom: 2px solid #6A479C;
    color: #2E2E2E;
    font-weight: bold;
}
