.client-view {
    padding: 0px;
    margin-top: 90px;
    overflow-x: hidden;
}

.client-view input{
    font-family: 'proxima-nova', sans-serif;

}

body, .client-view {
    font-family: 'proxima-nova', sans-serif;
}

.progress-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.custom-progress {
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 10px;
    background-color: #eee;
    overflow: hidden;
    margin: 5px 0;
    box-shadow: inset 0 0 10px #878582a1;
}

.custom-progress-bar {
    height: 100%;
    background: linear-gradient(to right, #B23092, #6A479C);
    transition: width 0.3s;
}

.checklist-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: center;
}

.section {
    background-color: #ffffff;
    border: 3px solid #ddd;
    border-radius: 20px;
    padding: 15px;
    width: 250px;
    height: 150px;
    cursor: pointer;
    transition: border-color 0.4s;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section.open {
    border-color: #70469C;
    padding: 15px;
}

.section-header {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}



.section-details {
    text-align: center;
    margin-bottom: 10px;
}

.section-details p {
    margin: 5px 0;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.client-greeting {
    text-align: center;
    font-size: 52px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #272728;
}

.client-greeting-gradient {
    text-align: center;
    font-size: 52px;
    margin-bottom: 20px;
    font-weight: 700;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.client-intro-text {
    text-align: center;
    margin-bottom: 50px;
    margin-left: 25%;
    margin-right: 25%;
    font-size: 18px;
    font-weight: 500;
}

.client-intro-text p {
    margin: 20px 0;
}

.item-container {
    margin-bottom: 10px;
}

.item-name-container {
    display: flex;
    align-items: center;
}

.item-name {
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: bold;
    color: #323232;
    line-height: 1.2em; /* or any other value you prefer */
    margin-left: 0px; /* Adjust spacing as needed */
}

.completed-checkmark {
    color: green;
    font-size: 18px;
    margin-right: 5px; /* Adjust spacing as needed */
}

.completed-item-text {
    display: block;
    font-size: 14px;
    color: #777;
    margin-top: 2px;
    margin-left: 4px;
}

.question-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0px;
}

.question-item {
    margin-bottom: 30px;
}

.item-flex-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.item-container {
    flex-basis: 30%;
    flex-grow: 1;
}



.item-description {
    font-size: 14px;
    margin: 0;
    margin-top: 0px;
    color: #777;
    line-height: 1.4em; /* or any other value you prefer */
}

.item-input {
    flex-basis: 70%;
    flex-grow: 0;
    margin-left: 40px;
}

.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.input-container label {
    width: 100px;
    font-size: 16px;
    font-weight: bold;
    color: #666;
    text-align: right;
    margin-right: 10px;
}

.input-container input,
.business-hours-container .input-container input,
.input-container .textbox {
    flex-grow: 1;
    margin-left: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.input-container input:hover,
.business-hours-container .input-container input:hover,
.input-container .textbox:hover {
    border-color: #c06ec3;
}

.input-container input:focus,
.business-hours-container .input-container input:focus,
.input-container .textbox:focus {
    border-color: #c06ec3;
    outline: none;
}

.business-hours-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
}

.business-hours-container button {
    width: fit-content;
}

.business-hours-container .input-container input {
    flex-grow: 0;
    width: 200px; /* Adjust this value as needed */
}

.checklist-header {
    font-size: 30px;
    color: #2E2E2E;
    position: relative;
    margin-bottom: 40px; /* Adjust the margin as needed */
    text-align: left; /* Align text to the left */
}

.checklist-header::after {
    content: '';
    display: block;
    width: 50px; /* Adjust the width as needed */
    height: 4px; /* Adjust the height as needed */
    background: linear-gradient(to right, #B23092, #6A479C);
    margin: 10px 0 0; /* Adjust the margin as needed */
    border-radius: 2px; /* Optional: to make the bar edges rounded */
    position: absolute;
    left: 0; /* Align to the left */
}


.section-questions {
    margin-left: 5%;
    margin-right: 5%;
}

.completed-item-text {
    font-size: 12px;
    color: #666;
}

.multiple-choice-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.multiple-choice-container button {
    width: fit-content;
    margin-top: 5px;
}

.multiple-choice-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.multiple-choice-option label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

.multiple-choice-option input[type="radio"] {
    appearance: none;
    width: 12px;
    height: 12px;
    background-color: #D5D5D5;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.multiple-choice-option input[type="radio"]:checked::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #B23092;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multiple-choice-option input[type="radio"]:not(:checked)::before {
    background: none;
}

.multiple-choice-option input[type="radio"].submitted:checked::before {
    background: green; /* Green color after submission */
}

.multiple-choice-option input[type="radio"]:not(:checked)::before {
    background: none;
}

.conditional-items {
    margin-top: 10px;
    padding-left: 0; /* Adjust indentation as needed */
}

.conditional-items {
    margin-top: 10px;
    padding-left: 0px; /* Adjust indentation as needed */
}

.conditional-item {
    margin-bottom: 30px;
}

.conditional-item .item-flex-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
}

.conditional-item .item-container {
    flex-grow: 1;
    flex-basis: 30%;
    /* Make sure to change regular one too */
}

.conditional-item .item-name {
    font-size: 18px;
    font-weight: bold;
    color: #323232;
}

.conditional-item .item-description {
    font-size: 14px;
    color: #777;
}

.conditional-item .item-input {
    flex-grow: 0;
    flex-basis: 70%;
    margin-left: 40px;
}

.input-container {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 0px;
    align-items: center;
}



.contact-input-container {
    display: flex;
    align-items: center;
    margin-left: 0px;
    gap: 10px;
    margin-bottom: 10px;
}



.input-container input {
    flex-grow: 1;
    margin-left: 0px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.input-container input:hover {
    border-color: #c06ec3;
}

.input-container input:focus {
    border-color: #c06ec3;
    outline: none;
}

.fileUpload{
    
}

.button {
    padding: 1px 15px;
    font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    border-radius: 25px;
    border: 2px solid #877e85;
    background-color: transparent;
    color: #877e85;
    font-size: 14px;
    font-weight: 500;
    margin-left: 0px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    display: inline-block;
}

.input-container .button,
.multiple-choice-container .button,
.checkbox-container .button,
.business-hours-container .button,
.contact-input-container .button,
.adScheduleContainer .button,
.checklist-text-container .submitButton {
    padding: 1px 15px;
    font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    border-radius: 25px;
    border: 2px solid #125d46;
    background-color: #125d46;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    display: inline-block;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s forwards;
}

.adScheduleContainer .button{
    margin-top: 10px;
}

.input-container .button:hover, .multiple-choice-container .button:hover,
.checkbox-container .button:hover {
    background-color: #0b3b2d;
    color: white;
    border: 2px solid transparent;
}


.deleteBtn {
    padding: 1px 15px;
    font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    border-radius: 25px;
    border: 2px solid #a81d40;
    background-color: #a81d40;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    display: inline-block;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s forwards;
}


.uploadBtn {
    padding: 1px 15px;
    font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    border-radius: 25px;
    border: 2px solid #125d46;
    background-color: #125d46;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    display: inline-block;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s forwards;
}


.uploadBtn:disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    color: #666;
    cursor: not-allowed;
}

.uploadBtn:disabled:hover {
    background-color: #ccc;
    color: #666;
    border: 2px solid #ccc;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.button:hover {
    background-color: #B23092;
    color: white;
    border: 2px solid transparent;
}

.completed-checkmark {
    width: 20px; /* Set the width to match the original checkmark size */
    height: 20px; /* Set the height to match the original checkmark size */
    vertical-align: middle;
    margin-right: 8px; /* Adjust spacing as needed */
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkbox-container button {
    width: fit-content;
}

.checkbox-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox-option label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

.checkbox-option input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.checkbox-option input[type="checkbox"]:checked {
    background-color: #B23092;
}

.checkbox-option input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}

.input-container label{
    width: fit-content;
}

.edited-item-text {
    display: block;
    font-size: 12px;
    color: #666;
    margin-top: 2px;
    margin-left: 4px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
} */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}






.new-item-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.new-item-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.new-item-input, .new-item-select, .new-item-textarea {
    flex-grow: 1;
    margin-left: 10px;
    font-family: 'proxima-nova', sans-serif;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.new-item-input:hover, .new-item-select:hover, .new-item-textarea:hover {
    border-color: #c06ec3;
}

.new-item-input:focus, .new-item-select:focus, .new-item-textarea:focus {
    border-color: #c06ec3;
    outline: none;
}

.new-item-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.new-item-subheader {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

/* --------------- Hero */

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    background: #000000;
    position: relative;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.hollow-circle {
    position: absolute;
    top: 20%; /* Adjust the top position as needed */
    left: 40%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%); /* Center the circle */
    width: 64px; /* Set the width of the circle */
    height: 64px; /* Set the height of the circle */
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.084); /* Create the outline */
    border-radius: 50%; /* Make it a circle */
    background: transparent; /* Ensure the circle is hollow */
}

.hollow-circle-bottom {
    position: absolute;
    top: 150%; /* Adjust the top position as needed */
    left: 20%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%); /* Center the circle */
    width: 700px; /* Set the width of the circle */
    height: 700px; /* Set the height of the circle */
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.084); /* Create the outline */
    border-radius: 50%; /* Make it a circle */
    background: transparent; /* Ensure the circle is hollow */
}

.hollow-circle-top {
    position: absolute;
    top: 0%; /* Adjust the top position as needed */
    left: 60%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%); /* Center the circle */
    width: 121px; /* Set the width of the circle */
    height: 121px; /* Set the height of the circle */
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.084); /* Create the outline */
    border-radius: 50%; /* Make it a circle */
    background: transparent; /* Ensure the circle is hollow */
}

.hollow-circle-right {
    position: absolute;
    top: 4%; /* Adjust the top position as needed */
    left: 112%; /* Adjust the left position as needed */
    transform: translate(-50%, -50%); /* Center the circle */
    width: 800px; /* Set the width of the circle */
    height: 800px; /* Set the height of the circle */
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.084); /* Create the outline */
    border-radius: 50%; /* Make it a circle */
    background: transparent; /* Ensure the circle is hollow */
}

.circle {
    position: absolute;
    top: -250px; /* Adjust the top position as needed */
    left: -200px; /* Adjust the left position as needed */
    width: 500px;
    height: 550px;
    z-index: 1;
    background: url('../assets/Gradient.png') no-repeat center center;
    opacity: 0.4; /* Adjusted opacity */
    background-size: cover;
    filter: blur(50px); /* Adjust the blur level as needed */
    border-radius: 50%; /* Ensure it remains a circle */
}

.circle2 {
    position: absolute;
    top: 100px; /* Adjust the top position as needed */
    left: 1500px; /* Adjust the left position as needed */
    width: 800px;
    height: 800px;
    z-index: 1;
    background: url('../assets/Gradient.png') no-repeat center center;
    opacity: 0.33; /* Adjusted opacity */
    background-size: cover;
    filter: blur(50px); /* Adjust the blur level as needed */
    border-radius: 50%; /* Ensure it remains a circle */
}

.dot-array {
    position: absolute;
    top: 32%; /* Adjust the top position as needed */
    left: 3%; /* Adjust the left position as needed */
    opacity: 10%;
    transform: translateY(-50%); /* Center the array vertically */
    width: 175px; /* Set the width of the array */
    height: 175px; /* Set the height of the array */
    z-index: 2; /* Ensure it appears above the gradient */
    background: url('../assets/Dot_Array.png') no-repeat center center;
    background-size: contain; /* Ensure it scales correctly */
}

.dot-array2 {
    position: absolute;
    top: 78%; /* Adjust the top position as needed */
    left: 87%; /* Adjust the left position as needed */
    opacity: 10%;
    transform: translateY(-50%); /* Center the array vertically */
    width: 175px; /* Set the width of the array */
    height: 175px; /* Set the height of the array */
    z-index: 2; /* Ensure it appears above the gradient */
    background: url('../assets/Dot_Array.png') no-repeat center center;
    background-size: contain; /* Ensure it scales correctly */
}

.hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    z-index: 3;
}

.title {
    width: fit-content;
    max-width: 65%;
    line-height: 75px;
    font-weight: 700;
}

/* Add keyframes for the fade-in-left animation */
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Ensure the initial state is hidden and positioned offscreen */
.welcomeText,
.secondaryText {
    opacity: 0;
    transform: translateX(-50px);
}

/* Apply the animation to the title and secondaryTitle elements */
.welcomeText {
    animation: fadeInLeft 1s ease-out forwards;
    animation-delay: 0.5s; /* Delay for the title */
    animation-fill-mode: forwards;
}

.secondaryText {
    animation: fadeInLeft 1s ease-out forwards;
    animation-delay: 1s; /* Staggered delay for the secondaryTitle */
    animation-fill-mode: forwards;
}


.title h1 {
    font-size: 74px;
    color: #ffffff;
    margin: 0;
    font-weight: 600;
}

.body-text {
    width: fit-content;
    max-width: 40%;
    font-weight: 600;
    line-height: 30px;
    color: white;
}

.body-text p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 0; /* Ensure the top margin is zero */
}

.body-text a {
    color: #ff4081;
    text-decoration: none;
}

.body-text a:hover {
    text-decoration: underline;
}

.secondaryText {
    text-align: center;
    font-size: 88px;
    margin-bottom: 20px;
    font-weight: 700;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Increase padding on wider screens */
@media (min-width: 1500px) {
    .hero-content {
        padding-left: 150px;
        padding-right: 150px;
    }
}

/* ------------------ Cards */

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 150px;
    padding: 20px;
    text-align: center;
    position: relative;
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(20px); /* Start with translateY 20px */
    cursor: pointer; /* Added cursor pointer for better UX */
    transition: all 0.3s ease, background-color 0.5s ease, padding 0.5s ease, transform 0.3s ease; /* Added transition for smooth animation */
    animation: fadeInUp 1.0s forwards; /* Add the animation */
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.card-content h3 {
    font-size: 21px;
    font-weight: 700;
    color: #2E2E2E;
    margin: 10px 0 10px;
}

.card-content .completion {
    margin: 0 0 10px;
    font-size: 14px;
    color: #6c757d;
}

.progress-bar {
    background-color: #E4DEED;
    border-radius: 10px;
    overflow: hidden;
    height: 20px;
    margin-bottom: 10px;
}

.progress-bar .progress {
    background-color: #6f42c1;
    background: linear-gradient(to right, #B23092, #6A479C);
    height: 100%;
}

.card-content .due-date,
.card-content .completed-date {
    margin: 0;
    font-size: 14px;
    color: #2E2E2E;
    position: absolute;
    font-weight: 500;
    bottom: 6px;
    left: 0;
    right: 0;
    text-align: center;
}

/* This is what should be changed on the selected one */
.card.selected .due-date {
    position: absolute;
    bottom: 0%;
    color: white;
    background-color: #5C2D91;
    border-radius: 0 0 15px 15px;
    padding: 5px 0;
}

.card.selected .completed-date {
    position: absolute;
    bottom: 0%;
    color: white;
    background-color: #5C2D91;
    border-radius: 0 0 15px 15px;
    padding: 5px 0;
}

.card.complete .checkmark-circle {
    display: flex;
    z-index: 10; /* Ensure it stays on top */
}

.checkmark-circle {
    display: none; /* Hidden by default, shown only for complete cards */
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-color: #5C2D91;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 10; /* Ensure it stays on top */
}

.checkmark-circle span{
    font-weight: 600;
}

/* End cards */

.instructions{
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #2E2E2E;
    padding-bottom: 0px;
}

.instructionsSecondLine{
    font-size: 22px;
    text-align: center;
    margin-top: 0px;
    font-weight: 800;
    margin-bottom: 0px;
    color: #2E2E2E;
}

.instructionsThirdLine{
    text-align: center;
    width: fit-content;
    font-weight: 500;
    font-size: 15px;
    background-color: #d5d5d5;
    padding: 4px;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 25px;
    margin: 0px;
    margin-top: 5px;
}

.instructionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers the content */
    align-items: center; /* Horizontally centers the content */
    height: fit-content; /* Ensure the container takes the full height */
}


@keyframes waggle {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.waggle {
    animation: waggle 1s ease-in-out 2;
}

.client-view textarea {
    font-family: 'proxima-nova', sans-serif;
    width: 100%;
    min-height: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    resize: none;
    overflow: hidden;
}

.fileInstructions{
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
    color: #7e7e7e;
}

.client-view textarea:hover {
    border-color: #c06ec3;
}

.client-view textarea:focus {
    border-color: #c06ec3;
    outline: none;
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.editModeButton {
    position: relative;
    background-color: #6A479C;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    z-index: 0;
}

.editModeButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #B23092, #6A479C);
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: -1;
    border-radius: 50px;
}

.editModeButton:hover::before {
    opacity: 1;
}

.editModeButton:hover {
    transform: scale(1.05);
}

.delete-section-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(171, 2, 2);
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.checklist-sections .material-icons {
    font-size: 15px; /* Adjust as needed */
    color: rgb(255, 255, 255);
}

.delete-section-button:hover {
    background-color: darkred;
}

.add-section-card{
    background-color: #6A479C;
    color: white;
}

.add-section-card h3{
    color: white;
}

.add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200px; /* Adjust the size as needed */
    color: #6A479C; /* Adjust the color as needed */
    margin-top: 10px; /* Add some spacing if needed */
}

.add-icon span{
    font-size: 24px;
    padding: 10px;
    background-color: white;
    color: #6A479C;
    font-weight: 700;
    border-radius: 50px;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
}

.new-section-form {
    margin-top: 20px;
}

.new-section-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 2px;
    display: block;
    text-align: left;
}

.new-section-input {
    width: calc(100% - 16px);
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'proxima-nova', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.new-section-input:hover{
    border-color: #c06ec3;
}

.or-separator {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.or-separator::before,
.or-separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin: 0 10px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.modal-buttons .submit-button,
.modal-buttons .cancel-button{
    width: 48%;
}

.cancel-button,
.submit-button {
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid transparent;
    background-color: #70469C;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.cancel-button {
    background-color: white;
    color: #70469C;
    border-color: #70469C;
}

.cancel-button:hover {
    background-color: #70469C;
    color: white;
}

.submit-button:hover {
    background-color: #50326f;
    color: #ffffff;
    border-color: #50326f;
}

.new-section-form .new-section-input, 
.new-section-form .custom-dropdown-select {
    width: 100%;
    max-width: 3750px;  /* Ensure max width is the same */
    margin: 10px 0;
    box-sizing: border-box;
}

.new-section-input:focus{
    border-color: #c06ec3;
    outline: none;

}

.delete-item-button{
    border: none;
    background-color: none;
    font-weight: 600;
    cursor: pointer;
    padding-top: 10px;
    color: red;
}

.delete-item-button:hover{
    border: none;
    background-color: none;
    font-weight: 600;
    padding-top: 10px;
    text-decoration: underline;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
}

.new-item-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
}

.new-item-form textarea{
    margin-left: 10px;
    width: 330px;
}

.new-item-label{
    text-align: start;
    margin-left: 10px;
}

.new-item-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.new-item-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.new-item-input, .new-item-select, .new-item-textarea {
    flex-grow: 1;
    margin-left: 10px;
    font-family: 'proxima-nova', sans-serif;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.new-item-input:hover, .new-item-select:hover, .new-item-textarea:hover {
    border-color: #c06ec3;
}

.new-item-input:focus, .new-item-select:focus, .new-item-textarea:focus {
    border-color: #c06ec3;
    outline: none;
}

.new-item-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.new-item-subheader {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.new-item-form .checkbox-option{
    margin-left: 10px;
}

.add-checklist-item-button{
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    background-color: #50326f;
    color: white;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
}

.add-checklist-item-button:hover{
    background-color: #332047;
    
}

.deleteDocBtn{
    color: rgb(162, 162, 162);
    transition: color 0.3s;
}

.deleteDocBtn:hover{
    color: #c06ec3;
}

.fileContainer{
    margin-bottom: 5px;
}

/* Hide the original file input */
/* Hide the original file input */
.fileUpload {
    display: none;
}

.file-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between file actions */
}

.file-action-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px;

    border-radius: 5px;
    background-color: #f9f9f9;
}


/* Style the custom file upload button */
.custom-file-upload {
    display: flex; /* Use flexbox */
    align-items: center; /* Center the content vertically */
    padding: 3px 15px;
    cursor: pointer;
    background-color: #7a11aa;
    color: white;
    border: none;
    font-weight: 600;
    border-radius: 25px;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;

    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s forwards;
    text-align: center;
}

.file-name {
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.file-name.visible {
    opacity: 1;
}

.custom-file-upload:hover {
    background-color: #500b70;
}

.custom-file-upload .material-symbols-outlined {
    margin-right: 8px; /* Add some space between the icon and the text */
    font-size: 20px; /* Adjust the size of the icon if needed */
}


.upload-progress-container {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 10px;
}

.upload-progress-bar {
    width: 80px;
    background-color: #a5a5a5;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    margin-left: 10px;
}

.upload-progress {
    height: 8px;
    background-color: #4caf50;
    width: 0;
    transition: width 0.4s ease;
}


.upload-progress-percentage {
    margin-left: 10px;
    font-size: 14px;
    color: #333;
}

.button:disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    color: #666;
    cursor: not-allowed;
}

.button:disabled:hover {
    background-color: #ccc;
    color: #666;
    border: 2px solid #ccc;
}


.adScheduleContainer {
    margin-top: 20px;
}

.adScheduleTable {
    width: 100%;
    border-collapse: collapse;
}

.adScheduleTable th, .adScheduleTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.adScheduleTable th {
    background-color: #f2f2f2;
    font-weight: bold;
}

td label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

td input[type="text"] {
    flex-grow: 1;
    margin-left: 0px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

td input[type="text"]:hover {
    border-color: #c06ec3;
}

td input[type="text"]:focus {
    border-color: #c06ec3;
    outline: none;
}

.contact-input-container input {
    flex-grow: 1;
    margin-left: 0px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.contact-input-container input:hover {
    border-color: #c06ec3;
}

.contact-input-container input:focus {
    border-color: #c06ec3;
    outline: none;
}

.business-hours-table th{
    text-align: left;
    font-size: 12px;
    color: #6b6b6b;
}

.business-hours-table td{
    font-weight: 600;
}

.editor-container {
    margin-bottom: 20px;
}

.toolbar {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.toolbar button {
    background: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
}

.toolbar button:hover {
    background: #e0e0e0;
}


.text-container{
    display: flex;
    flex-direction: column;
}



.textarea-wrapper {
    position: relative;
    display: flex;
    align-items: center; /* Align children vertically */
    width: 100%;
    flex-direction: row;
}

.quill-wrapper {
    position: relative;
    display: flex;
    align-items: center; /* Align children vertically */
}

.quill-wrapper .ql-container {
    width: 100%;
    box-sizing: border-box;
}

.quill{
    width: 100%;
}

.question-item a{
    color: #d92bdf;
}