.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #000000 30%, #4b2d57 60%, #6e4181 70%, #9F3B9C 100%);
    position: relative;
    font-family: 'proxima-nova', sans-serif;
    overflow: hidden;
    
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.loginBox {
    background: #ffffff;
    padding: 40px;
    width: 360px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 1;
}

.loginContainer .logo {
    max-width: 175px;
    margin-bottom: 10px;
}

.clientLoginTitle {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 110%;
}

.googleButton {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
}

.googleButton:hover {
    background-color: #f1f1f1;
}

.input {
    width: 339px; /* Adjust the width as needed */
    padding: 10px;
    margin: 10px 0;
    font-family: 'proxima-nova', sans-serif;

    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}


.loginButton {
    width: 100%;
    padding: 10px;
    background-color: #6d02a8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.loginButton:hover {
    background-color: #590090;
}

.signupText {
    font-size: 14px;
    color: #666;
    margin-top: 20px;
}

.signupText a {
    color: #6d02a8;
    text-decoration: none;
}

.signupText a:hover {
    text-decoration: underline;
}

.signInLogo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}


.signInButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: 20px;
    position: relative;
    z-index: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.signInButton:before {
    content: "";
    background: linear-gradient(
      45deg,
      #7a00ff,
      #6e4181,
      #9F3B9C,
      #7a00ff
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 25px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.signInButton:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    border-radius: 25px;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkboxOption {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkboxOption label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

.checkboxOption input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.checkboxOption input[type="checkbox"]:checked {
    background-color: #B23092;
}

.checkboxOption input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}


.loginOptions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.loginOption {
    padding: 10px 7px;
    border: none;
    border-radius: 25px;
    width: 150px;
    font-weight: 500;
    cursor: pointer;
    background-color: #694997;;
    border: none;
    color: #ffffff;
    transition: all 0.3s ease;
}

.loginOption:hover {
    background-color: #5a3e80;
    transform: translateY(-5px);
    color: white;
}

.loginOption h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    color: inherit;
}