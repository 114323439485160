.adminForm {
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 30px; /* Adjust the margin to make space for the navbar */

}

.adminForm h1 {
    font-size: 42px;
    margin-bottom: 5px;
    color: #272728;
}

.adminForm h2 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #272728;
}

.secondaryTitle{
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

button {
    font-family: 'proxima-nova', sans-serif;
}

.inputGroup {
    margin-bottom: 15px;
}



.inputGroup input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.section {
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sectionHeader h4 {
    margin: 0;
    color: #2E2E2E;
    font-size: 26px;
    font-weight: bold;
}

.sectionActions {
    display: flex;
    align-items: top;
    gap: 10px;
    height: 30px;
}

.sectionActions p{
    margin: 0;
    align-self: center;
}

/* .sectionActions button{
    width: 200px;
} */

.hideSection,
.deleteSection {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.hideSection {
    background-color: #6A479C;
    color: white;
    width: 200px;
}

.deleteSection {
    background-color: #B84B6C;
    color: white;
    width: 220px;
}

.sectionItems {
    margin-top: 10px;
}



.itemRowHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
    font-weight: bold;
    font-size: 12px;
    color: #666;
    grid-gap: 20px;
    margin-bottom: 20px;
    text-align: left;
}

.itemRow {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
    align-items: start;
    margin-bottom: 10px;
    grid-gap: 15px;
    text-align: left;
}

.itemHeader {
    text-align: left;
}

.itemName {
    text-align: left;
    font-weight: bold;
    color: #2E2E2E;
    line-height: 1.2em; /* or any other value you prefer */
}

.itemType {
    text-align: left;
}

.itemDescription {
    text-align: left;
    width: 100%;
}

.itemOptions {
    text-align: left;
    width: 100%;
}

.itemDate {
    text-align: left;
}

.conditionalItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 65px;
}

.conditionalItemRow {
    display: flex;
    width: 800px;
    align-items: center;
    margin-bottom: 4px;
}

.conditionalItemRow label {
    flex: 0 0 70px;
    font-size: 12px;
    font-weight: 400;
    color: #666;
}

.conditionalItemRow input,
.conditionalItemRow select {
    flex: 1;
}

.conditionalItemsWrapper {
    grid-column: 2 / 3;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
}

.conditionalItemRowWrapper {
    display: flex;
    flex-direction: row;
}

.addConditionalItem,
.deleteItem,
.addItem {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addConditionalItem {
    background-color: #b8c8d9;
    color: white;
}

.deleteItem {
    background-color: #B84B6C;
    color: white;
}

.addItem {
    background-color: #28a745;
    color: white;
}

.newItem {
    margin-top: 20px;
}

.submit {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
}

.message {
    margin-top: 20px;
    font-size: 16px;
    color: #28a745;
}

.activeUrls {
    margin-top: 30px;
}

.activeUrls h3 {
    font-size: 24px;
    font-weight: bold;
}

.activeUrls ul {
    list-style-type: none;
    padding: 0;
}

.clientItem {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deleteButton {
    background: none;

    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}


.emptyOptions {
    text-align: left;
    width: 100%;
    height: 20px; /* or any other value to match the height of the textarea */
}

.actionButtons {
    display: flex;
    gap: 2px;
}

.addConditionalButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 2px;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.addConditionalButton img {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

.textbox {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.textbox:hover {
    border-color: #c06ec3;
}

.textbox:focus {
    border-color: #c06ec3;
    outline: none;
}


.sectionNameInput{
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 30%;
    font-size: 16px;
    font-weight: 600;
    color: #272728;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sectionNameInput:focus {
    border-color: #c06ec3;
    outline: none;
}

.sectionNameInput:hover {
    border-color: #c06ec3;
}



.newItem {
    margin-top: 20px;
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: flex-start;
}

.newItem input,
.newItem select,
.newItem 
.newItem textarea {
    width: 300px; /* Adjust width as needed */
    margin-bottom: 10px;
}

.newItem textarea {
    height: 100px; /* Adjust height as needed */
}

.newItem button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addItemButton {
    background-color: #28a745;
    color: white;
}

.cancelButton {
    background-color: #dc3545;
    color: white;
}

.createCustomItemButton {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #666;
    color: white;
    cursor: pointer;
}

.lockButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 2px;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.lockButton img {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}





.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
}

.modalContent h4{
    margin-top: 0px;
}

.modalContent h2 {
    margin-top: 0;
    margin-bottom: 0px;
}

.modalContent ul {
    list-style: none;
    padding: 0;
}

.modalContent li {
    margin: 10px 0;
}

/* .modalContent button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6a479c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */

.modalContent .confirmModal,
.modalContent .closeModal{
    width: 48%;
}

.closeModal,
.confirmModal {
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid transparent;
    background-color: #70469C;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.closeModal {
    background-color: white;
    color: #70469C;
    border-color: #70469C;
}

.closeModal:hover {
    background-color: #70469C;
    color: white;
}

.confirmModal:hover {
    background-color: #50326f;
    color: #ffffff;
    border-color: #50326f;
}
.modalContent img{
    width: 150px;
}

.modalContent .buttonGroup { /* New class for button group */
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* ----- Client Info */

.clientInfoHeader {
    display: flex;
    align-items: top;
    gap: 10px;
    line-height: 20px;
    margin-bottom: 10px;
}

.formSection {
    margin-bottom: 20px;
}

.formSection h3 {
    font-size: 20px;
    color: #6A479C;
    margin-bottom: 10px;
}


.clientInfoHeader .icon {
    width: 36px;
    height: 36px;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-mask: url('../assets/icons/contact_mail_24dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    mask: url('../assets/icons/contact_mail_24dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.slackIcon{
    width: 36px;
    height: 36px;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-mask: url('../assets/icons/slack-svgrepo-com.svg') no-repeat center;
    mask: url('../assets/icons/slack-svgrepo-com.svg') no-repeat center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.clientInfoHeader h2 {
    font-size: 24px;
    margin: 0;
    color: #272728;
}

.clientInfoHeader p {
    font-size: 14px;
    color: #666;
    margin: 0;
}

/* .section {
    margin-bottom: 20px;
} */

.twoColumnGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.threeColumnGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px; /* Ensure the grid items are aligned at the center */
}

.inputGroup {
    margin-bottom: 0px; /* Remove margin-bottom from input groups */
}

.inputGroup label {
    display: block;
    font-weight: 500;
    color: #2E2E2E;
    margin-bottom: 0px;
    padding-left: 3px;
}

.inputGroup input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.removeContactButton {
    color: #727272;
    border: 1px solid #ccc;
    background-color: #F3F6F9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center; /* Align the button at the center of the grid */
    transition: ease all 0.3s;
}


.removeContactButton span {
    font-size: 15px; /* Adjust the size of the icon */
    color: rgb(119, 119, 119);
}

.removeContactButton:hover span {
    font-size: 15px; /* Adjust the size of the icon */
    font-weight: 600;
    color: #c06ec3;
}

.addContactButton{
    color: #727272;
    border: 1px solid #ccc;
    background-color: #F3F6F9;
    border-radius: 25px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    transition: ease all 0.2s;
}

.addContactButton:hover {
    font-weight: 600;
    color: #c06ec3;
}

.addContactButton:hover, .removeContactButton:hover {
    border-color: #c06ec3;
}

.removeContactButton{
    transform: translate(-20%, 40%);
}

.divider {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 0px 0px;
    margin-bottom: 10px;
}

/* Checklist Section */

.checklistSection {
    margin-bottom: 20px;
}

.checklistHeader {
    display: flex;
    align-items: top;
    gap: 10px;
    line-height: 20px;
    margin-bottom: 10px;
}

.checklistIcon {
    width: 36px;
    height: 36px;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-mask: url('../assets/icons/checklist_rtl_24dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    mask: url('../assets/icons/checklist_rtl_24dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.knownItemsIcon {
    width: 36px;
    height: 36px;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-mask: url('../assets/icons/fact_check_30dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    mask: url('../assets/icons/fact_check_30dp_5F6368_FILL1_wght400_GRAD0_opsz24.svg') no-repeat center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.checklistHeader h2 {
    font-size: 24px;
    margin: 0;
    color: #272728;
}

.checklistHeader p {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.keySection {
    margin-top: 10px;
    margin-bottom: 25px;
}

.keySection h3 {
    font-size: 20px;
    color: #6A479C;
    margin-top: 10px;
    margin-bottom: 10px;
}

.keyItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
}

.lockKeyIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 4px;
    border-radius: 4px;
    color: #484848;

}

.conditionalKeyIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 4px;
    border-radius: 4px;
    color: #484848;
}

.dateInput{
    width: fit-content; /* Adjust width as needed */
    margin-right: 10px; /* Add some margin to the right of the date input */
    font-family: 'proxima-nova', sans-serif;
}

.sectionHeader .divider{
    margin-bottom: 30px;
}

.textarea {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    font-family: 'proxima-nova', sans-serif;
    border-radius: 5px;
    vertical-align: top;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    resize: none; /* Prevent manual resizing */
    overflow: hidden; /* Hide scroll bar */
}

.textarea:hover {
    border-color: #c06ec3;
}

.textarea:focus {
    border-color: #c06ec3;
    outline: none;
}

.moveButton{
    background-color: none;
    border: none;
    color: none;
    background: none;
    transition: ease 0.3s;
}

.moveButton:hover{
    background-color: none;
    border: none;
    color: #c06ec3;
    background: none;
}

.createClientButton {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 50px;
    background-color: #6a479c;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.createClientButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #B23092, #6A479C);
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 50px;
}

.createClientButton:hover::before {
    opacity: 1;
}

.createClientButton:hover {
    border-color: #6a479c;
    color: transparent;
}

.createClientButton .buttonText,
.createClientButton .buttonArrow {
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    z-index: 1;
}

.createClientButton .buttonArrow {
    position: absolute;
    color: white;
    bottom: -40px;
    opacity: 0;
}

.createClientButton:hover .buttonText {
    transform: translateY(-100%);
    opacity: 0;
}

.createClientButton:hover .buttonArrow {
    transform: translateY(-50px);
    opacity: 1;
}

.conditionalIcon {
    color: #a3a3a3;
    transition: color 0.3s;
}

.conditionalIcon:hover {
    color: #4A67D5;
}

.deleteIcon {
    color: #a3a3a3;
    transition: color 0.3s;
}

.deleteIcon:hover {
    color: #B84B6C;
}

.lockIcon {
    color: #a3a3a3;
    transition: color 0.3s;
}

.lockIcon:hover {
    color: rgb(46, 46, 46);
}

.lockedIcon {
    color: #484848;
}


.toggleItemsButton{
    color: #727272;
    border: 1px solid #ccc;
    background-color: #F3F6F9;
    border-radius: 25px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: ease all 0.2s;
}

.toggleItemsButton:hover {
    color: #c06ec3;
    border: 1px solid #c06ec3;
}

.selectedButton {
    background-color: #c06ec3;
    color: white;
    border: 1px solid #c06ec3;
}

.selectedButton:hover {
    background-color: #a660a9;
    color: white;
    border: 1px solid #a660a9;
}

/* Add this CSS rule */
.radio-option {
    background-color: transparent; /* Default background color */
}

.radio-option.selected {
    background-color: pink; /* Background color for selected option */
}

.requiredButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 2px;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}


.requiredIcon {
    color: #484848;
}

.optionalIcon {
    color: #d1d1d1;
}

.adScheduleContainer {
    margin-top: 20px;
}

.adScheduleTable {
    width: 100%;
    border-collapse: collapse;
}

.adScheduleTable th, .adScheduleTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    vertical-align: middle; /* Ensure vertical alignment */
}

.adScheduleTable th {
    background-color: #f2f2f2;
    font-weight: bold;
}

td input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    margin: auto; /* Center the checkbox within its container */
}

td input[type="checkbox"]:checked {
    background-color: #B23092;
}

td input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}

td select {
    width: 100%;
    background: none;
    border: none;
    font-family: 'proxima-nova', sans-serif;
    height: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

/* Remove focus border */
td select:focus {
    outline: none;
    border: none;
}

/* Ensure no outline when selected */
td select:focus-visible {
    outline: none;
    border: none;
}



.item-input input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    margin: auto; /* Center the checkbox within its container */
}

.input-container input[type="checkbox"]:checked {
    background-color: #B23092;
}

.input-container input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}


.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkbox-wrapper {
    margin-right: 8px;
}

.text-input-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
}

.text-input-wrapper label {
    margin-right: 8px;
}

td label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
}


.emailTable {
    width: 75%;
    border-collapse: collapse;
    margin: 0 auto;
    margin-bottom: 30px;
}


.emailTable td {
    padding: 8px;
    border: none; /* Invisible grid */
    text-align: left;
}

.emailTable td span{
    text-align: left;
}

.redDot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.greenDot {
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}


/* Add this to your CSS file */
.slackChannelNameTextbox {
    border: 2px solid #ff4081; /* Example color for the border */
    padding: 10px;
    font-size: 16px;
    width: 300px; /* Adjust as needed */
    border-radius: 5px;
  }
  
  .characterCounter {
    margin-top: 5px;
    font-size: 12px;
    color: #888; /* Example color for the text */
  }
  

  .textboxError {
    padding: 10px;
    border: 3px solid #b52b50;
    border-color: #b52b50;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.textboxError:hover {
    border: 3px solid #b52b50;
    box-shadow: 0 0 10px rgba(181, 43, 80, 0.5);
}

.textboxError:focus {
    border: 3px solid #b52b50;
    box-shadow: 0 0 10px rgba(181, 43, 80, 0.5);
    outline: none;
}

.textarea-wrapper{
    position: relative;
    display: flex;
    align-items: center; /* Align children vertically */
    width: 100%;
    flex-direction: row;
}

.textarea-wrapper textarea {
    font-family: 'proxima-nova', sans-serif;
    width: 100%;
    min-height: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    resize: none;
    overflow: hidden;
}

.textarea-wrapper textarea:hover {
    border-color: #c06ec3;
}

.textarea-wrapper textarea:focus {
    border-color: #c06ec3;
    outline: none;
}

.errorMessage {
    color: #b52b50;
    font-size: 12px;
    margin-top: 5px;
}

.disabled {
    background-color: #f0f0f0;
    color: #888;
    cursor: not-allowed;
}

.formSection input[type="checkbox"] {
    appearance: none;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background-color: #D5D5D5;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.formSection input[type="checkbox"]:checked {
    background-color: #B23092;
}

.formSection input[type="checkbox"]:checked::after {
    content: '\e876'; /* Unicode for the Material Icons checkmark */
    font-family: 'Material Icons';
    font-weight: 600;
    color: white;
    font-size: 16px; /* Adjust the size as needed */
}

.checkboxLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxLabel input {
    margin-right: 10px;
    width: 20px;
}

.inputDistroMembers {
    margin-top: 15px;
    opacity: 1;
}
.checkboxDistro{
    margin-bottom: 15px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Adjust as needed for spacing */
}

/* Add this in your CSS file */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #50326f;
    width: 40px;
    height: 40px;
    
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
