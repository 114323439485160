.clientLogsContainer {
    padding: 20px;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 60px;
    overflow-x: hidden;
}

.logsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.logsTable th,
.logsTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.logsTable th {
    background-color: #f2f2f2;
}

.searchContainer {
    position: relative;
    margin-bottom: 20px;
}

.searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #ccc;
}

.searchInput {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.searchInput:focus + .searchIcon,
.searchContainer:hover .searchIcon {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

h1 {
    font-size: 42px;
    margin-bottom: 12px;
    color: #272728;
}

.secondaryTitle {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.logsTable th:nth-child(1), .logsTable td:nth-child(1) {
    width: 15%; /* Adjust width as needed */
}

.logsTable th:nth-child(2), .logsTable td:nth-child(2) {
    width: 70%; /* Adjust width as needed */
}

.logsTable th:nth-child(3), .logsTable td:nth-child(3) {
    width: 15%; /* Adjust width as needed */
}

.logsTable tr:hover {
    background-color: #dddddd; /* Light gray background on hover */
}

.logWarning{
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #B23092;
}