.superAdminDashboard {
    margin-top: 0px;
    font-family: 'proxima-nova', sans-serif;
}

.dashboard {
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: 'proxima-nova', sans-serif;
}

.header {
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    font-size: 24px;
    color: #6A479C;
}

.tilesContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.tile {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 30%;
    text-align: center;
}

.tile h2 {
    font-size: 18px;
    color: #6A479C;
    margin-bottom: 10px;
}

.tile p {
    font-size: 36px;
    color: #6A479C;
    margin: 0;
}

.detailsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.detailTile {
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 49%;
    padding: 0; /* Remove padding to ensure tileBody is directly under tileTop */
    align-items: center;
}

.tileBody {
    padding: 0 20px;
    margin-top: 0; /* Ensure no margin on top */
}

.detailTileFull ul,
.detailTile ul {
    list-style: none;
    padding: 0;
}
.detailTileFull li,
.detailTile li {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.editInput {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 14px;
    margin-right: 10px;
    text-align: left;
}

.addNew {
    color: #6A479C;
    cursor: pointer;
}

.addNew:hover {
    text-decoration: underline;
}

.editButton {
    background: none;
    border: none;
    color: #6A479C;
    cursor: pointer;
    font-size: 14px;
}

.version {
    font-size: 14px;
    font-style: italic;
    color: #6A479C;
}

.fullWidthTile {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.fullWidthTile h3 {
    font-size: 18px;
    color: #6A479C;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.section {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f900;
    text-align: left;
}

.sectionHeader {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 20px;
    gap: 10px; /* Optional: Adds spacing between elements */
}

.itemRowHeader {
    display: grid;
    grid-template-columns: 2fr 1fr 3fr 2fr 1fr 1fr 1fr;
    font-weight: bold;
    font-size: 12px;
    color: #666;
    grid-gap: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.itemRow {
    display: grid;
    grid-template-columns: 2fr 1fr 3fr 2fr 1fr 1fr 1fr;
    align-items: start; /* Align items to the start */
    margin-bottom: 10px;
    grid-gap: 10px;
    text-align: left;
}

.defaultChecklistItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    text-align: left;
}

.fullWidthTile .editInput {
    width: 100%;
}

.dueDateDays {
    width: 60px;
}




.conditionalItemRow {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.conditionalItemRow .editInput {
    width: 100%;
}

.conditionalItemDisplay {
    margin-top: 10px;
    padding: 10px;
    background: #f0f0f0;
    border-radius: 5px;
}

.conditionalItemDisplay div {
    margin-bottom: 5px;
}

.actionButtons {
    display: flex;
    gap: 10px;
}

.addConditionalButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: none;
    justify-content: center;
    margin-bottom: 5px;
    padding: 2px;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.addConditionalButton img {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

.itemDeleteButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #B84B6C;
    justify-content: center;
    padding: 2px;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.itemDeleteButton img {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}



/* Previous button styles */

.addNew {
    color: #6A479C;
    cursor: pointer;
}

.addNew:hover {
    text-decoration: underline;
}

.editButton {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
}

.deleteButton {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
}

.moveButton {
    background: none;
    border: none;
    color: #6A479C;
    cursor: pointer;
    font-size: 14px;
}

.sectionHeader .editInput {
    width: 60%;
}

.sectionHeader span {
    margin: 0 5px;
    text-align: left;
}

.dueDateDays {
    width: 60px;
}

.tileTop {
    background: linear-gradient(to right, #B23092, #6A479C);
    border-radius: 12px 12px 0px 0px;
    height: 52px;
}

.tileTop h3 {
    font-size: 22px;
    color: #6A479C;
    margin-bottom: 0px;
    padding: 10px;
    margin-left: 10px;
    color: white;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detailTileFull li,
.detailTile li {
    font-size: 14px;
}

.connectedApps {
    width: 80%;
    margin: 20px auto;
    border-radius: 8px;
    padding: 20px;
}

.connectedApps h2 {
    margin: 0 0 20px 0;
}

.appCard {
    background: rgba(255, 255, 255, 0.526);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.134);
    backdrop-filter: blur(9.1px);
    -webkit-backdrop-filter: blur(9.1px);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.appHeader {
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    align-items: center;
    gap: 30px;
}

.appInfo {
    display: flex;
    align-items: center;
}

.appInfo img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.appName {
    font-size: 22px;
    font-weight: 600;
}

.status {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.status .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.status.available {
    color: green;
}

.status.available .dot {
    background-color: green;
}

.status.error {
    color: red;
}

.status.error .dot {
    background-color: red;
}

.detailsButton {
    background: none;
    border: none;
    color: #4a4a4a;
    font-family: 'proxima-nova', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.detailsButton .material-icons {
    margin-left: 5px;
    font-size: 20px;
}

.detailsButton[aria-expanded="true"] .material-icons {
    transform: rotate(180deg);
}

.itemRow ul {
    margin: 0px;
    margin-bottom: 10px;
}

.downloadButton {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
}

.uploadLabel {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
}

.uploadInput {
    display: none;
}





.detailsContainer {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 10px;
}

.detailTile {
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 49%; /* Set width to 49% to allow two items per row */
    padding: 0;
    margin-bottom: 10px; /* Add margin bottom for spacing */
}

.detailTileFull {
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Set width to 49% to allow two items per row */
    padding: 0;
    margin-bottom: 10px; /* Add margin bottom for spacing */
}

.teamMemberItem {
    display: flex;
    align-items: center;
    gap: 10px; /* Reduce the gap between the image and text */
}

.detailTileFull li{
    justify-content: start;
}

.profilePic {
    width: 40px; /* Adjust the size as needed */
    height: 40px;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image covers the entire circle */
    transition: transform 0.6s ease-in-out; /* Smooth transition for the hover effect */
}

@keyframes spin {
    0% {
        transform: perspective(400px) rotateY(0deg);
    }
    100% {
        transform: perspective(400px) rotateY(360deg);
    }
}

.profilePic:hover {
    animation: spin 0.6s 1;
}


.sectionName {
    font-size: 24px;
    font-weight: bold;
    color: #363538;
    margin: 0px;
}

.dueDate{
    font-size: 16px;
    color: #363538;
    margin: 0px;
    font-weight: 500;
}

.sectionNameEdit {
    font-size: 24px;
    font-weight: bold;
    color: #363538;
    margin: 0px;
    margin-bottom: 10px;
    width: 700px;
    background: none;
    font-family: 'proxima-nova', sans-serif;
    border: none;
    border-bottom: 2px solid #3636361f;
    outline: none;
    transition: border-bottom 0.3s ease-in-out; /* Smooth transition */
}

.sectionNameEdit:focus {
    outline: none;
    border-bottom: 2px solid #6A479C;
}

.sectionNameEditContainer{
    display: flex;
    flex-direction: column;
    height: 110px;
}

.dueDateContainer{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-family: 'proxima-nova', sans-serif;
}

.dueDateDays{
    font-weight: bold;
    color: #363538;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    width: 45px;
    text-align: center;
    background: none;
    font-family: 'proxima-nova', sans-serif;
    border: none;
    border-bottom: 2px solid #3636361f;
    outline: none;
    transition: border-bottom 0.3s ease-in-out; /* Smooth transition */
}

.dueDateDays:focus {
    outline: none;
    border-bottom: 2px solid #6A479C;
}

.sectionNameEditContainer {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    background-color: white;
    width: 90%;
    padding: 20px;
    border-radius: 16px;
}

.sectionActionButtons{
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-left: 10px;
    flex-direction: column;
    background-color: white;
    border-radius: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.deleteButton{
    padding: 0px;
    transition: 0.3s ease-in-out;

}

.collapseButton{
    padding: 0px;
    transition: 0.3s ease-in-out;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.deleteButton:hover .icon {
    filter: brightness(0) saturate(100%) invert(27%) sepia(80%) saturate(3505%) hue-rotate(347deg) brightness(90%) contrast(89%);
}


.icon{
    
}

.expanded .icon{
    fill: #6A479C;
}


.addSectionContainer {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    width: 100%; /* Ensures full width */
    position: relative; /* Keeps it aligned inside the parent */
}

.addSectionButton {
    background: none;
    border: 2px solid #6A479C;
    color: #6A479C;
    padding: 5px 15px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
    align-self: center;
    font-family: 'proxima-nova', sans-serif;
    justify-content: center;
    transition: 0.3s ease-in-out;
}

.addSectionButton:hover {
    background: #6A479C;
    color: white;
    border: 2px solid #6A479C;
}




.itemNameTypeEditContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px; 
    margin-bottom: 10px;
    margin-top: 5px;
}

.editItemName,
.editItemType {
    flex: 1; 
    max-width: 50%;
    min-width: 50%; 
    box-sizing: border-box;
}


.editItemName{
    padding: 5px;
    font-size: 16px;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 600;
    border: none;
    color: #363538;
    border-bottom: 2px solid #3636361f;
    outline: none;
    transition: border-bottom 0.3s ease-in-out; /* Smooth transition */
}

.editItemName:focus {
    outline: none;
    border-bottom: 2px solid #6A479C;
}

.editItemType{
    border: none;
}

.editItemName input,
.editItemType select {
    width: 100%; 
    padding: 10px; 
    border: 1px solid #ccc; 
    box-sizing: border-box;
    appearance: none; 
}


.editItemDescription{
    padding: 5px;
    font-size: 14px;
    font-family: 'proxima-nova', sans-serif;
    font-weight: normal;
    width: 100%;
    border: none;
    color: #363538;
    border-bottom: 2px solid #3636361f;
    outline: none;
    margin-bottom: 10px;
    transition: border-bottom 0.3s ease-in-out; /* Smooth transition */
}

.editItemDescription:focus {
    outline: none;
    border-bottom: 2px solid #6A479C;
}


.textTypeInputContainer{
    width: 200px;
    margin-bottom: 10px;
}



.itemInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 9; /* Takes up 90% */
    padding: 20px;
    background-color: white;
    max-width: 90%; /* Ensures it doesn't exceed */
    border-radius: 16px;
    min-height: 145px;
}

.itemActionButtons {
    display: flex;
    flex-wrap: wrap; /* Allows buttons to wrap if needed */
    justify-content: flex-end; /* Aligns buttons to the right */
    align-items: center;
    flex: 1; /* Takes up 10% */
    background-color: white;
    padding: 10px;
    padding-top: 15px;
    border-radius: 25px;
    flex-direction: column;
    align-content: center;
    max-width: 30px; /* Ensures it doesn’t exceed */
}


.innerItemContainer {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 20px; /* Adds spacing between elements */
    width: 100%;
    align-items: flex-start
}






.itemLockButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 2px;
    color: #5f6368;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.itemLockButton img {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

.itemLockButton.locked {
    background-color: none; /* Dark grey color for locked state */
}

.itemLockButton.unlocked {
    background-color: none; /* Green color for unlocked state */
}

.itemOptionalButton{
    background-color: none;
    background: none;
    color: #5f6368;
    border: none;
    margin-top: 5px;
}

.itemOptionalButton.notOptional{
    color: #6a479f;
}

.itemOptionalButton.optional{
    color: #d3d3d3;
}





/*Multiple Choice Options*/
.optionInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.optionInputWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.editInput {
    flex-grow: 1;
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.deleteOptionButton {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #5f6368; /* Red delete button */
}

.addOptionButton {
    background: none;
    color: #5f6368;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    padding-left: 0px;
    text-align: start;
}


.multipleChoiceOption{
    border: none;
    background: none;
    width: fit-content;
    color: #363538;
    width: 50%;
    font-family: 'proxima-nova', sans-serif;

}

.optionInputWrapper input:focus,
.optionInputWrapper input:focus-visible {
    border: none; /* Removes border on focus */
    outline: none; /* Ensures no outline */
    box-shadow: none; /* Removes any potential shadows */
}


.checkbox{
    width: 10px;
    height: 10px;
    border: solid 1px #5f6368;
    border-radius: 2px;
}

.circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #5f6368;
}

.conditionalItemContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 9; /* Takes up 90% */
    padding: 20px;
    background-color: white;
    max-width: 90%; /* Ensures it doesn't exceed */
    border-radius: 16px;
    min-height: 145px;
}


.showOnContainer{
    display: flex;
    flex-direction: row;
    width: 75%;
    gap: 0px;
    align-items: center
}

.showOnText{
    font-size: 12px;
    padding: 0px;
    width: 60px;
    color: #5f6368;
}


.conditionalItem{
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 20px; /* Adds spacing between elements */
    width: 89%;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 10%;
}

.conditionalItem .itemActionButtons{
    border-radius: 50px;
}