body {
    font-family: 'proxima-nova', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #f4f6f9;
    margin: 0;
    padding: 0;
}

.connectedApps {
    width: 80%;
    margin: 20px auto;
    border-radius: 8px;
    padding: 20px;
}

.connectedApps h2 {
    margin: 0 0 20px 0;
}

.appCard {
    background: rgba(255, 255, 255, 0.526);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.134);
    backdrop-filter: blur(9.1px);
    -webkit-backdrop-filter: blur(9.1px);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.appHeader {
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    align-items: center;
    gap: 30px;
}

.appInfo {
    display: flex;
    align-items: center;
}

.appInfo img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.appName {
    font-size: 22px;
    font-weight: 600;
}

.status {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.status .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.status.available {
    color: green;
}

.status.available .dot {
    background-color: green;
}

.status.error {
    color: red;
}

.status.error .dot {
    background-color: red;
}

.detailsButton {
    background: none;
    border: none;
    color: #4a4a4a;
    font-family: 'proxima-nova', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.detailsButton .material-icons {
    margin-left: 5px;
    font-size: 20px;
}

.detailsButton[aria-expanded="true"] .material-icons {
    transform: rotate(180deg);
}
