/* Additional or updated CSS for the NavBar component */
.navBar {
    background-color: #ffffff;
    color: black;
    padding: 10px 10px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1000;
    transition: padding 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.2);
    height: 70px;
}

.navBar.scrolled {
    padding: 10px;
    height: 40px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
}

.navBarContent {
    display: flex;
    align-items: center;
}

.logo {
    height: 70px;
    width: auto;
    transition: height 0.3s ease;
}

.navBar.scrolled .logo {
    height: 40px;
    width: auto;
}

.hamburger {
    background: none;
    border: none;
    color: #666;
    font-size: 24px;
    cursor: pointer;
}

.navBar.scrolled .hamburger {
    font-size: 20px;
    transition: all 0.3s ease;
}

.sideNav {
    height: 100%;
    width: 350px;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: -350px;
    background: linear-gradient(to bottom right, #A43694, #74459B);
    overflow-x: hidden;
    transition: left 0.5s ease;
    padding-top: 60px;
}

.sideNav.open {
    left: 0;
}

.sideNavHeader {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
}

.profilePic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    background-color: white;
    display: flex; /* Added */
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    text-align: center; /* Center text alignment */
}


.profilePic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileInfo {
    color: white;
}

.profileInfo h3 {
    margin: 0;
    font-size: 18px;
}

.profileInfo p {
    margin: 0;
    font-size: 14px;
    opacity: 0.8;
}

.sideNavLinks {
    padding: 0 10px;
}

.nameFirstChar{
    font-size: 28px;
    font-weight: 800;
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sideNavLinks h4 {
    color: white;
    opacity: 0.8;
    margin-left: 10px;
    margin-bottom: 10px;
}

.sideNavLinks a,
.sideNavLinks button {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    gap: 20px;
}

.sideNavLinks a i.material-icons,
.sideNavLinks button i.material-icons {
    font-size: 34px;
}

.sideNavLinks .material-icons span{
    font-size: 28px;
}

.sideNavLinks a:hover,
.sideNavLinks button:hover {
    color: #f1f1f1;
    background-color: #9c68b9;
    border-radius: 12px;
    text-decoration: none;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 36px;
    margin-left: 50px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}


.sideNavLinks a {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    transition: 0.3s;
    gap: 20px;
    width: calc(100% - 40px); /* Ensures the link spans the entire width of the parent */
    box-sizing: border-box; /* Includes padding in the element's total width */
}

.sideNavLinks a:hover {
    color: #f1f1f1;
    background-color: #9c68b9;
    border-radius: 12px;
    text-decoration: none;
    margin: 0; /* Removes any margin to avoid affecting the width */
    padding: 8px 20px; /* Adjust padding to maintain the correct size */
    width: calc(100% - 40px); /* Ensures the link spans the entire width of the parent */
}
