.container {
    padding: 20px;
    overflow-x: hidden;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 80px;
}

.editButtonContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.editButton,
.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d3d3d3;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-right: 10px;
}

.saveButton {
    background: linear-gradient(to right, #B23092, #6A479C);
}

.editButton .material-symbols-outlined {
    font-size: 24px;
    color: white;
}

.saveButton .saveIcon {
    font-size: 24px;
    color: white;
}

.saveButton span {
    font-size: 24px;
    color: white;
}

.deleteButton {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.deleteButton:hover {
    background-color: #c9302c;
}

.secondaryTitle {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.error {
    color: red;
    margin-top: 20px;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    position: relative;
}

.table th {
    background-color: #f2f2f2;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
}

.modalActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirmButton {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.confirmButton:hover {
    background-color: #c9302c;
}

.cancelButton {
    background-color: #ccc;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.cancelButton:hover {
    background-color: #999;
}

.addUserForm {
    margin-bottom: 20px;
}

.inputGroup {
    margin-bottom: 15px;
}

.inputGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.textbox {
    width: 95%; /* Adjusted width to fit within table cells */
    max-width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.textbox:hover {
    border-color: #c06ec3;
}

.textbox:focus {
    border-color: #c06ec3;
    outline: none;
}

.addButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.addButton:hover {
    background-color: #218838;
}
