.selectCompanyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f7f7f7;
    padding: 20px;
}

.selectCompanyBox {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: center; /* Center the text */
}

.logo {
    width: 100px;
    margin-bottom: 20px;
}

.selectCompanyTitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.companyList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.companyItem {
    background: #fafafa;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background 0.3s;
}

.companyItem:hover {
    background: #f0f0f0;
}

.companyItem button {
    text-decoration: none;
    color: #333;
    display: block;
    background: none;
    border: none;
    cursor: pointer;
}

.error {
    color: red;
    margin-top: 20px;
}
