.container {
    padding: 20px;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 50px;
    overflow-x: hidden;
}

.container input:hover {
    border-color: #c06ec3;
}

.container input:focus {
    border-color: #c06ec3;
    outline: none;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px; /* Adjust as needed for spacing */
    margin-left: 10px;
}

.editButtonContainer {
    display: flex;
    align-items: center;
}

.editButton,
.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d3d3d3;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-right: 10px;
}

.saveButton {
    background: linear-gradient(to right, #B23092, #6A479C);
}

.editButton .material-symbols-outlined {
    font-size: 24px;
    color: white;
}

.saveButton .saveIcon {
    font-size: 24px;
    color: white;
}

.saveButton span {
    font-size: 24px;
    color: white;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    /* border: 1px solid #ddd; */
    padding: 8px;
    padding-left: 26px;
    text-align: left;
}

.table tbody tr {
    border-bottom: 1px dashed #d2d2d2; /* Adjust the color and thickness as needed */
}

/* Remove borders from the table header */
.table thead tr {
    border-bottom: none;
}

.table th {
    background-color: #f2f2f2;
    color: #6b737f;
}

.table td select,
.table td button {
    padding: 5px;
}

.searchContainer {
    position: relative;
    margin-bottom: 20px;
}

.searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #ccc;
}

.searchInput {
    width: 40%;
    padding: 15px 15px 15px 40px;
    /* Adjust padding for icon */
    font-size: 16px;
    font-family: 'proxima-nova', sans-serif;

    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 5px;
}



.searchInput:focus + .searchIcon,
.searchContainer:hover .searchIcon {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
}

.modalContent h3{
    font-size: 22px;
    margin-left: 20px;
    margin-right: 20px;
}

.modalActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirmButton {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.confirmButton:hover {
    background-color: #c9302c;
}

.cancelButton {
    background-color: #ccc;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.cancelButton:hover {
    background-color: #999;
}

.container h2 {
    font-size: 42px;
    margin-bottom: 20px;
    color: #272728;
}

.container h4 {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.secondaryTitle {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.columnHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activeSort {
    color: purple;
}


.activeSort {
    color: purple;
}

.deleteUser{
    border: none;
    color: #dc3545;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #dc35451a;
    font-weight: 800;
    cursor: pointer;
}

.deleteUser:hover{
    text-decoration: underline;
}

.deleteInput {
    margin-top: 10px;
    padding: 8px;
    width: 80%;
    border: 1px solid #ccc;
    font-family: 'proxima-nova', Times, serif;
    border-radius: 4px;
}

.deleteInput:focus{
    border: 1px solid #651861;
    outline: none;
}


.disabledButton {
    background-color: gray; /* Gray color for the disabled state */
    cursor: not-allowed; /* Optional: change the cursor to indicate it's disabled */
    opacity: 0.5; /* Optional: reduce opacity to emphasize the disabled state */
}

.confirmButton:disabled {
    background-color: gray;
    cursor: not-allowed;
    opacity: 0.5;
}

.sortIcon {
    margin-left: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-size: 0.8em;
    vertical-align: middle;
}

/* Rotate the icon based on sort direction */
.ascending {
    transform: rotate(180deg);
}

.descending {
    transform: rotate(0deg);
}


.bigTable {
    background-color: white;
    border-radius: 24px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
    -moz-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
    box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
}

.searchExtra{
    padding: 20px;
    padding-bottom: 5px;
}

.viewPortalBtn{
    display: inline-block;
    padding: 4px 8px;
    background-color: #a528a71a; 
    color: #684997 !important;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
}

.viewUsersBtn{
    display: inline-block;
    padding: 4px 8px;
    background-color: rgba(0, 123, 255, 0.1); /* Light blue background */
    color: #007bff;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
}

.viewLogsBtn{
    display: inline-block;
    padding: 4px 8px;
    background-color: rgba(40, 167, 69, 0.1); /* Light green background */
    color: #28a745;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
}