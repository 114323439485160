/* src/components/Footer.css */
.footer {
    background-color: #000000;
    color: #ffffff55;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-logo {
    width: 50px;
    height: auto;
    display: block;
    margin: 0 auto 10px auto;
    
}

footer img{
    width: 120px;
    margin-bottom: 15px;
}

.footerText{
    margin: 0px;
}

.footer a{
    color: #ffffff55;
    text-align: center;
    padding: 0px;
    position: relative;
    bottom: 0;
    width: 100%;
}