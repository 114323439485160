/* CustomQuill.css */

.ql-container {
    flex-grow: 1;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.ql-toolbar {
    background-color: #f7f7f7; /* Change the toolbar background color */
    border: 1px solid #ccc; /* Change the toolbar border color */
    border-top: none; /* Remove the top border */
    border-radius: 0 0 4px 4px; /* Add rounded corners to the bottom */
    display: flex;
    align-items: center; /* Vertically center the buttons */
    padding: 0 8px;
    height: 37px;
}

.ql-toolbar button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    font-size: 12px; /* Make the icons smaller */
    line-height: 1;
    display: flex;
    align-items: center; /* Vertically center the icons */
}



.ql-toolbar button:hover svg {
    fill: #5C2D91; /* Change the icon color on hover */
}

.ql-toolbar .ql-active {
    background-color: #d0d0d0; /* Change the active button background color */
}

.ql-toolbar .ql-active svg {
    fill: #5C2D91; /* Change the icon color when active */
}

.ql-toolbar button svg {
    width: 15px; /* Adjust the size of the SVG icons */
    height: 15px; /* Adjust the size of the SVG icons */
    display: block;
    margin: auto;
    fill: currentColor; /* Ensure the fill color can be inherited */
}

.ql-editor {
    font-size: 13px; /* Change the font size */
    line-height: normal; /* Adjust the line height */
    font-family: 'proxima-nova', sans-serif;
    padding: 0px;
    font-style: normal; /* Unitalicize the text */
}

.ql-clean {
    padding: 0px;
    margin: 0px;
}

.ql-editor.ql-blank::before {
    color: #4d5053; /* Match the placeholder text color */
    font-style: normal; /* Ensure the placeholder text is not italic */
    font-size: 13px; /* Match the font size */
    margin-left: -6px;
    font-weight: 300;
}

.ql-container:hover {
    border-color: #c06ec3;
}

.ql-container:focus-within {
    border-color: #c06ec3;
}

.ql-formats {
    display: flex;
    align-items: center; /* Vertically center the buttons */
    height: 100%;
}

.quill-container {
    width: 100%;
}

.ql-toolbar {
    border-radius: 4px;
    margin-top: 10px;
    width: fit-content;
    height: 39px;
    background-color: none;
    background: none;
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: #5C2D91 !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: #5C2D91 !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: #5C2D91 !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: #5C2D91 !important;
  stroke: none;
}

.toolbar {
    display: none;
    opacity: 0;
    transition: opacity .4s ease-in-out; /* Adjust the duration and timing function as needed */
}

.toolbar.hidden{
    display: none !important;
}

.toolbar.visible {
    display: block;
    opacity: 1;
}

