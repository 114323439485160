/* Toast customization */
.custom-toast-container .Toastify__toast {
    border-radius: 8px;
    font-family: 'proxima-nova', sans-serif;
}

/* .custom-toast-container .Toastify__toast--success {
    background-color: #ffffff;
} */

.custom-toast-container .Toastify__toast--error {
    background-color: #ffffff; /* Red color for error */
    color: rgb(184, 26, 26);
    font-weight: 700;
}


/* .custom-toast-container .Toastify__toast-body {
    font-size: 14px;
} */

/* .custom-toast-container .Toastify__close-button {
    color: white;
} */
