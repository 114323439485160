.activityLogContainer {
    padding: 20px;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 50px;
}

.activityLogTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.activityLogTable th, .activityLogTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.activityLogTable th {
    background-color: #f2f2f2;
    cursor: pointer;
}

.activityLogTable th .columnHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activityLogTable th .material-icons {
    font-size: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

.activityLogTable th .material-icons.sort {
    color: #ccc;
}

.activityLogTable th .material-icons.arrow_drop_up,
.activityLogTable th .material-icons.arrow_drop_down {
    color: #333;
}

.activityLogContainer .columnHeader span{
    color: #333;
}

.activityLogTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.activityLogTable tbody tr:hover {
    background-color: #f1f1f1;
}

.activityLogTable td {
    font-size: 14px;
    vertical-align: top;
}


/* ActivityLog.module.css */
/* ActivityLog.module.css */
.chevron {
    margin-left: 5px;
    transition: transform 0.3s ease;
    font-size: 0.8em;
    vertical-align: middle;
}

/* Add rotation for the ascending direction */
.ascending {
    transform: rotate(180deg);
}

/* Add rotation for the descending direction */
.descending {
    transform: rotate(0deg);
}
