.addUsersContainer {
    padding: 20px;
    font-family: 'proxima-nova', sans-serif;
    margin-top: 60px;
}

.addUsersForm {
    margin-bottom: 20px;
}

.addUsersContainer input {
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    
    font-family: 'proxima-nova', sans-serif;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.addUsersContainer input:hover {
    border-color: #c06ec3;
}

.addUsersContainer input:focus {
    border-color: #c06ec3;
    outline: none;
}

.formGroup {
    margin-bottom: 10px;
    width: 30%;
}

.formGroup label {
    display: block;
    margin-bottom: 2px;
    font-weight: 800;
    font-size: 12px;
    color: #272728;
}

.formGroup input,
.formGroup select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.selectContainer{
    width: 30%;
}


.submitButton {
    padding: 10px 30px;
    background-color: #6d02a8;
    color: #fff;
    border: none;
    font-weight: 800;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: all ease 0.3s;
}

.submitButton:hover {
    background-color: #590090;
    transform: translateY(-5px);
}

.usersTable {
    width: 100%;
    border-collapse: collapse;
}

.usersTable th,
.usersTable td {
    /* border: 1px solid #ddd; */
    padding: 8px;
    padding-left: 26px;
    text-align: left;
}

.usersTable th {
    background-color: #f2f2f2;
    font-weight: 800;
    color: #687380;
    padding-top: 10px;
    padding-bottom: 10px;
}

.usersTable td select,
.usersTable td button {
    padding: 5px;
}

.searchContainer {
    position: relative;
    margin-bottom: 20px;
    width: 40%;
    
}

.searchContainer input{
    box-shadow: none;
}

.searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #ccc;
    /* Default color */
}

.searchInput {
    width: 100%;
    padding: 15px 15px 15px 40px;
    /* Adjust padding for icon */
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Apply the gradient when the input is focused or hovered */
.searchInput:focus+.searchIcon,
.searchContainer:hover .searchIcon {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    /* Fallback for browsers that do not support the properties */
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
}

.modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.confirmButton {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.confirmButton:hover {
    background-color: #c9302c;
}

.cancelButton,
.confirmButton {
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid transparent;
    color: white;
    width: 48%;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.cancelButton {
    background-color: #ccc;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.cancelButton:hover {
    background-color: #999;
}

.addUsersContainer h1 {
    font-size: 42px;
    margin-bottom: 12px;
    color: #272728;
}

.secondaryTitle {
    background: linear-gradient(to right, #B23092, #6A479C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.usersTable tr:hover {
    background-color: #dddddd;
    /* Light gray background on hover */
}

.deleteUser {
    border: none;
    color: red;
    border-radius: 25px;
    background-color: none;
    background: none;
}

.deleteUser:hover {
    text-decoration: underline;
}

.bigTable {
    background-color: white;
    border-radius: 24px;
    -webkit-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
    -moz-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
    box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.064);
}

/*Menu */

/* Add this to AddUsers.module.css */
.menuContainer {
    position: relative;
    display: inline-block;
}

.dotsButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
}

.popupMenu {
    position: absolute;
    top: 0px; /* Adjust this value as needed */
    right: 30px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 100;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.popupMenu button {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 8px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
}

.popupMenu button:hover {
    background-color: #f2f2f2;
}

.popupMenu button svg {
    font-size: 16px;
}

.popupMenu button:nth-child(2) {
    color: red;
}


/* Add these to AddUsers.module.css */
.adminText {
    display: inline-block;
    padding: 4px 8px;
    background-color: rgba(0, 123, 255, 0.1); /* Light blue background */
    color: #007bff;
    font-weight: 800;
    font-size: 14px;
    border-radius: 4px;
}

.accountMgrText {
    display: inline-block;
    padding: 4px 8px;
    background-color: rgba(40, 167, 69, 0.1); /* Light green background */
    color: #28a745;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
}

.superAdminText {
    display: inline-block;
    padding: 4px 8px;
    background-color: #dc35451a; /* Light red background */
    color: #dc3545;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
}

.searchExtra{
    padding: 20px;
    padding-bottom: 5px;
}

/* Add these to AddUsers.module.css */
.sortIcon {
    margin-left: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
    font-size: 0.8em;
    vertical-align: middle;
}

/* Rotate the icon based on sort direction */
.ascending {
    transform: rotate(180deg);
}

.descending {
    transform: rotate(0deg);
}



